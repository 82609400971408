import React, { useEffect } from 'react'
import App from '@src/components/app/App'
import AlertModal from '@src/components/commons/modal/alertModal/AlertModal'
import useReduxModal from '@src/hooks/useModal'
import { GetServerSidePropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import HeadContainer from '@src/components/HeadContainer'
interface IProps {
  deepLinkResult: string
}

const AppURLPage = ({ deepLinkResult }: IProps) => {
  const modal = useReduxModal()

  useEffect(() => {
    if (deepLinkResult === 'error') {
      modal.openModal({
        component: () => <AlertModal type="error" title={`지원하지 않는 링크입니다`} />,
      })
    }
  }, [])
  return (
    <>
      <HeadContainer title="APP" />
      <App />
    </>
  )
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const { locale } = ctx
  const deepLinkValue = ctx.query.deep_link_value || ('' as string)
  const deepLinkSub1 = ctx.query.deep_link_sub1 || ''

  if (!deepLinkValue) {
    return {
      props: {
        ...(await serverSideTranslations(locale ?? '', ['layout'])),
      },
    }
  } else {
    switch (deepLinkValue) {
      case '/notice':
        return {
          props: {
            ...(await serverSideTranslations(locale ?? '', ['layout'])),
          },
          redirect: {
            destination: `/customer/notice`,
            permanent: false,
          },
        }
      case '/notice/content':
        return {
          props: {
            ...(await serverSideTranslations(locale ?? '', ['layout'])),
          },
          redirect: {
            destination: `/customer/notice/${deepLinkSub1}`,
            permanent: false,
          },
        }
      case '/history/charge':
        return {
          props: {
            ...(await serverSideTranslations(locale ?? '', ['layout'])),
          },
          redirect: {
            destination: `/user/charge_history/${deepLinkSub1}`,
            permanent: false,
          },
        }
      case '/notice_setting':
        return {
          props: {
            ...(await serverSideTranslations(locale ?? '', ['layout'])),
          },
          redirect: {
            destination: `/user/notification`,
            permanent: false,
          },
        }
      case '/point':
        if (deepLinkSub1 === 'view') {
          return {
            props: {
              ...(await serverSideTranslations(locale ?? '', ['layout'])),
            },
            redirect: {
              destination: `/user/membership/vPoint`,
              permanent: false,
            },
          }
        } else {
          return {
            props: {
              ...(await serverSideTranslations(locale ?? '', ['layout'])),
            },
            redirect: {
              destination: `/user/membership/vPoint?action=${deepLinkSub1}`,
              permanent: false,
            },
          }
        }
      case '/membership_card/password_change':
        if (deepLinkSub1) {
          return {
            props: {
              ...(await serverSideTranslations(locale ?? '', ['layout'])),
            },
            redirect: {
              destination: `/user/membership/membership_card/reset_pw?id=${deepLinkSub1}`,
              permanent: false,
            },
          }
        } else {
          return {
            props: {
              ...(await serverSideTranslations(locale ?? '', ['layout'])),
              deepLinkResult: 'error',
            },
          }
        }
      case '/notification':
        return {
          props: {
            ...(await serverSideTranslations(locale ?? '', ['layout'])),
          },
          redirect: {
            destination: `/user/notification`,
            permanent: false,
          },
        }
      case '/charger/qr':
        return {
          props: {
            ...(await serverSideTranslations(locale ?? '', ['layout'])),
          },
          redirect: {
            destination: `/q?qrID=${deepLinkSub1}`,
            permanent: false,
          },
        }
      default: {
        return {
          props: {
            ...(await serverSideTranslations(locale ?? '', ['layout'])),
            deepLinkResult: 'error',
          },
        }
      }
    }
  }
}
export default AppURLPage
